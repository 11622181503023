import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import RemoveMd from 'remove-markdown'
import classNames from 'classnames'

const ns = `past-event`

const PastEvents = () => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const allHoldingCourtPosts = useStaticQuery(graphql`
    query AllHoldingCourtPostsQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "holding-court-post" } } }
      ) {
        nodes {
          frontmatter {
            title
            sub_title
            featuredimage {
              publicURL
            }
          }
          fields {
            slug
          }
        }
      }
    }
  `)

  return (
    <div className={rootClassnames}>
      <div className={`${ns}__related-banner ${ns}__related-banner--left`}>
        <p>PAST EVENTS</p>
      </div>
      <div className={`${ns}__related-items `}>
        {allHoldingCourtPosts.allMarkdownRemark.nodes.map((item, index) => (
          <div key={index} className={`${ns}__related-item`}>
            <a href={item.fields.slug}>
              <img
                src={item.frontmatter.featuredimage.publicURL}
                alt={item.frontmatter.title}
              />
            </a>
            <a href={item.fields.slug}>
              <p className="text-center title">{item.frontmatter.title}</p>
            </a>
            <p className="text-center">
              {RemoveMd(item.frontmatter.sub_title)}
            </p>
          </div>
        ))}
      </div>
      <div className={`${ns}__related-banner ${ns}__related-banner--right`}>
        <p>PAST EVENTS</p>
      </div>
    </div>
  )
}

export default PastEvents
